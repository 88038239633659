import React from 'react'
import { MDXTag } from '@mdx-js/tag'


import Img from "gatsby-image";
import SEO from '../../components/seo';
import ImageLandscape from '../../components/sub/landscape';
export const frontmatter = {
  title: "FlexState: Thriving in the Age of Disruption",
  date: "2019-03-12",
  author: "Roberto Ramos",
  authorTitle: "Senior Vice President, Strategy and Communications of The Doneger Group",
  teaserImage: "../../../static/media/flexstate.jpg",
  path: "2019-03-12-FlexState-Thriving-in-the-Age-of-Disruption",
  teaserText: "In a world of increasing algorithm-driven precision, instability is still ironically, or perhaps understandably, a persistent form of normal. It seems that shocks to the system, outliers and inherent human fear of change are triggering wild swings across the board."
};
const layoutProps = {
  frontmatter
};
export default class MDXContent extends React.Component {
  constructor(props) {
    super(props);
    this.layout = null;
  }

  render() {
    const {
      components,
      ...props
    } = this.props;
    return <MDXTag name="wrapper" components={components}>

      <SEO title="FlexState: Thriving in the Age of Disruption" description="For Breast Cancer Awareness Month, brands and consumers looking to shop it forward can do so via a host of month-long activations and limited-edition merchandise. Building a sense of community while providing real-time impact, these socially conscious and give back models continue to inspire consumers." author='Robeto Ramos' keywords={['Pink', 'Breast', 'Cancer', 'Athleta']} />
      <ImageLandscape imageSrc="20190312-flexstate-2.jpg" caption="Tech Brands" />
      <MDXTag name="p" components={components}>{`In a world of increasing algorithm-driven precision, instability is still ironically, or perhaps understandably, a persistent form of normal. It seems that shocks to the system, outliers and inherent human fear of change are triggering wild swings across the board.`}</MDXTag>
      <MDXTag name="p" components={components}>{`We see this volatility most prominently in the powerful anti-establishment waves that have ushered in radical voices in governments around the world. We also see it in the continued disruption across industries powered by the combination of digital and social media, lower barriers to entry for smart new players and an increasingly informed, sophisticated and determined consumer.`}</MDXTag>
      <MDXTag name="p" components={components}>{`This degree of instability, triggered by the settling of powerful tectonic shifts of technology, demographics, changing cultural mores and engagement models, is the new normal companies must face, navigate and thrive in. The following is a primer on six of these big shifts, along with business and creative implications.`}</MDXTag>
      <MDXTag name="p" components={components}><MDXTag name="strong" components={components} parentName="p">{`1. Recalibrated Survival`}</MDXTag></MDXTag>
      <MDXTag name="p" components={components}>{`The modern human condition has put individuals on edge. A recent survey of American consumers by The Doneger Group, reveals that over half of the population faces some level of worry or anxiety, with job insecurity ranking at the top. This is especially difficult for younger consumers entering adult life with higher debt levels than any previous generation. There is also an increasing fear of the gig economy becoming the norm and the instability it will create. The global sociocultural and political noise further exacerbates these feelings, and recent high-profile digital security breaches put the sense of self-identify further at risk.`}</MDXTag>
      <MDXTag name="p" components={components}>{`Recalibrated Survival is the state of constant betterment and optimization required to stay current, competitive and attractive. It’s a way the individual seeks to get further ahead and break out of the cycle of hustle. While this survival mode is urgent, consumers see it as a means to an end consisting of security, stability, inspiration and wellness. Driving the conversation toward a survival mode are cultural unifiers such as music, film, community service, sports and other activities that bridge cultural schisms and give us relief through a common experience. Work, or the flexible, modern-day definition of work, is at the core of this conversation; companies and talent are primed to find better ways of creating a happy place and experience in terms of the new work environment.`}</MDXTag>
      <MDXTag name="p" components={components}>{`Recalibrated Survival values protection, guidance and personalization. We see the rise of products and services that enable the individual to get ahead, feel smarter and be more informed. Think of hyper-personalized recommendation platforms, niche subscription services, next-generation loyalty programs, quantified-me wellness platforms and the continued rise of alternative medicine and wellness practices. Education and skills-building products such as MasterClass will thrive, as individuals will want access to experiences that make them more creative and innovative to thrive in the ideas economy.`}</MDXTag>
      <MDXTag name="p" components={components}><MDXTag name="strong" components={components} parentName="p">{`2. Return to the Core`}</MDXTag></MDXTag>
      <MDXTag name="p" components={components}>{`Complexity and surface noise are creating unnecessary obstacles and mounting pressures for most of us. We deal with this stress by simplifying things around us and returning to the core: values, relationships and inspiring experiences. This tilt towards simplicity is carrying over to the business side with companies finding ways to simplify their corporate structures, as they also work to make their consumer journey more powerfully simple. We see a hunger for directness and immediacy in the continued rise of human-machine interactions as evidenced by the rise of conversational design and new innovations including voice assistants and smarter bots.`}</MDXTag>
      <MDXTag name="p" components={components}>{`Return to the Core will prize simplicity, design thinking, purpose-driven brands, smaller influencer circles and next-gen loyalty programs. Think of better integration of services and systems around more nimble intelligence and intuition. Home, wellness and experiential industries will find deeper meaning and potential in this shift.`}</MDXTag>
      <MDXTag name="p" components={components}><MDXTag name="strong" components={components} parentName="p">{`3. FlexState`}</MDXTag></MDXTag>
      <MDXTag name="p" components={components}>{`FlexState is the ability to shape shift, which will be the most prized skill in the near future. As models of engagement evolve, those individuals and organizations with broader transcendental skills, creative vision and networks will thrive. We see flexibility rise across the board, whether it is the gig economy and its pool of freelancers or corporations exploring new adjacent opportunity spaces and delivery channel strategies. In an era of continuous disruption, a spirit of experimental play will be critical for growth.`}</MDXTag>
      <MDXTag name="p" components={components}>{`FlexState will prize personalization, deep learning, multi-dimensional experiences, hybrid materials and interfaces, omnichannel and omnipersonal approaches, new forms of business collaborations and new forms of engagement. The arts meet business and tech: MFA meets MBA in re-educating new business skills.`}</MDXTag>
      <MDXTag name="p" components={components}><MDXTag name="strong" components={components} parentName="p">{`4. Radical Innovation`}</MDXTag></MDXTag>
      <MDXTag name="p" components={components}>{`Competition is heating up and industries are facing a moment of reckoning, where most are required to streamline and drastically reduce redundant inputs including resources, time, investments and environmental impact. The challenge is that this must shift at a time when the consumer wants more of everything. Companies that turn this challenge into an open innovation exercise will thrive. Early winners seem to be those with a strong purpose, fluid ecosystem and planning culture and an intuitive command of culture and society and the role their brands play in shaping these conversations. The smarter ones will also bring the consumer directly into the solution, creating the ultimate form of engagement.`}</MDXTag>
      <MDXTag name="p" components={components}>{`Radical Innovation will prize the human experience, talent-based strategic design and sustainability as the ultimate challenge, the circular company, purpose-led design and more direct forms of consumer/customer engagement.`}</MDXTag>
      <MDXTag name="p" components={components}><MDXTag name="strong" components={components} parentName="p">{`5. Post-Hype`}</MDXTag></MDXTag>
      <MDXTag name="p" components={components}>{`Hype has helped fuel our economy during the past 10 years. Direct-to-consumer change makers now face the moment of reckoning with their ability to scale up, be profitable and, perhaps most challenging, reign in and reshape their founder-led culture. We have high hopes that as these companies mature, they will form a new hybrid between the best of the disruptive values that gave them life with a next-generation corporation ethos that is more human and agile.`}</MDXTag>
      <MDXTag name="p" components={components}>{`Post-hype will prize the new emotionally connected CEO and management with new success metrics, such as work-life balance, the circular/sustainable company, conversational design, transparency and measurement. Tonality and messaging will also be more open, transparent, conversational, empathetic and overall, more collaborative.`}</MDXTag>
      <MDXTag name="p" components={components}><MDXTag name="strong" components={components} parentName="p">{`6. Trust Economy`}</MDXTag></MDXTag>
      <MDXTag name="p" components={components}>{`With trust hitting all-time low levels and our digital footprints lasting forever throughout the ether, consumers will increasingly make trust an important factor in the brand consideration and purchase journey. Companies will up their ante on the security front and dial up the narrative of security to their customers. Next-generation security brands like Lifelock will become more commonplace and current brand leaders will get more serious around their security plays, including how they structure relationships around data with their customers.`}</MDXTag>
      <MDXTag name="p" components={components}>{`The Trust Economy will value data privacy and control, emotional commerce, peer-to-peer selling, smaller circles of trust and next level blockchain.`}</MDXTag>
      <MDXTag name="p" components={components}><MDXTag name="strong" components={components} parentName="p">{`Takeaways`}</MDXTag></MDXTag>
      <MDXTag name="p" components={components}>{`As you navigate these shifts, it’s critical to manage change through the lens of your brand and what you stand for. Timeless brands are able to leverage their values to navigate change fluidly and most importantly, to lead change. Think of ways in which your brand can become a disruptor by listening, getting out of comfort zones and dreaming up new, formidable ways to bring consistent value to your customer. That space is where the power of relationships can result in transformative groundswells.`}</MDXTag>
           </MDXTag>;
  }

}
export const _frontmatter = {};
    